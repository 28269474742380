<h2>{{title}}</h2>

{{#extraInfo}}
    <h4>{{{this}}}</h4>
{{/extraInfo}}

{{#if instructions}}
    <h4 class="mt-2">{{instructionsHeader}}</h4>
    <div class="redemptionInstructions">
        {{{instructions}}}
    </div>
{{/if}}

{{{redemptionAdditionalInstructions}}}

{{#if terms}}
  <h4 class="mt-2">{{termsHeader}}</h4>
  <div class="redemptionTerms">
    {{{terms}}}
  </div>
{{/if}}
