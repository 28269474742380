<div>
  {{#each this}}
    {{#if name}}
      <h3>{{humanizedName}}</h3>
    {{/if}}

    {{#if alerts.length}}
      <ul>
        {{#each alerts}}
          <li class="text-left mb-1">{{text}}</li>
        {{/each}}
      </ul>
    {{/if}}
  {{/each}}
</div>