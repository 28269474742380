import '~/scripts/integrations/jquery-extended';
import Swal from 'sweetalert2';
import Select2 from '~/scripts/lib/Select2.js'
import moment from 'moment-timezone';
import HandlebarsTemplates from '~/scripts/integrations/handlebars-templates';

(function() {
  if (!window.location.origin) {
    window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '');
  }

  window.R = window.R || {};

  window.R.utils = {
    confetti: function() {
      // for starting the confetti

      function start() {
        setTimeout(function () {
          confetti.start()
        }, 1000); // 1000 is time that after 1 second start the confetti ( 1000 = 1 sec)
      };

      //  for stopping the confetti

      function stop() {
        setTimeout(function () {
          confetti.stop()
        }, 5000); // 5000 is time that after 5 second stop the confetti ( 5000 = 5 sec)
      };
      // after this here we are calling both the function so it works
      start();
      stop();
    },
    // A wait to load helper to run methods when the script that loads them may not be ready due to for instance async attribute.
    // This helper allows to check if an object is been created off window, such as window.Appcues. Easy to extend this method for multiple types of object detection.
    waitToLoad: function(objectOffWindow, callback) {
      var timer = 0, isReady = false;
      if (!objectOffWindow || !callback) {return;}

      timer = setInterval(function() {
        if (window[objectOffWindow]) {
          clearTimeout(timer);
          callback();
        }
      }, 400);

    },
    isFullscreen: function() { return window.location.href.indexOf("/grid") !== -1; },
    isIE: function() {
      var ua = window.navigator.userAgent;

      // Test values; Uncomment to check result …

      // IE 10
      // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

      // IE 11
      // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

      // Edge 12 (Spartan)
      // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

      // Edge 13
      // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

      var msie = ua.indexOf('MSIE ');
      if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      }

      var trident = ua.indexOf('Trident/');
      if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      }

      var edge = ua.indexOf('Edge/');
      if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
      }

      // other browser
      return false;
    },

    pingSignInPopup: function(callback, popup) {
      var that = this;

      var interval = setInterval(function() {
        console.log("ping", window.location.origin);
        popup.postMessage("Recognize wants to login in please", window.R.host);
      }, 1000);

      // TODO: ensure right domain for receiving/sending message.
      // TODO turn off event listener once hit.
      window.addEventListener("message", function(e) {
        if (e.origin === window.R.host) {
          console.log("message received", "logging in!!!!!!!");
          callback(arguments);
          clearInterval(interval);
        }
      }.bind(this), false);
    },

    checkLoginStatus: function(callback, opts, popup) {
      var data = {}, that = this, loginInterval;
      opts = opts || {};

      if (window.Office) {
        return opts.response.value.addEventHandler(Microsoft.Office.WebExtension.EventType.DialogMessageReceived, function () {
          callback();
        });
      }

      if (popup && !this.isIE()) {
        this.pingSignInPopup(amILoggedIn, popup);
      } else {
        loginInterval = setInterval(amILoggedIn, 2500);
      }

      function amILoggedIn() {

        jQuery.ajax({
          url: "/api/auth_status",
          beforeSend: function (xhr) { xhr.setRequestHeader('X-Recognize-Referrer', window.location.href); }, // for debugging
          dataType: "json"
        }).done(function (responseData) {

          if (responseData.status === "true" || responseData.status === true) {
            if (loginInterval) clearInterval(loginInterval);
            callback();
          }
        });
      }
    },

    openWindow: function(url, width, height, callback) {
      var popup, status;
      width = width || 1024;
      height = height || 768;

      console.log("url", url);

      if (window.Office) {
        popup = Office.context.ui.displayDialogAsync(url,
          {height: 90, width: 55, requireHTTPS: true},
          function (result) {
            if (callback) callback(result);
          });
      } else {
        popup = window.open(url, '_blank', 'location=yes,height='+height+',width='+width+',scrollbars=yes,status=yes');
        if (popup) {
          status = "succeeded";
        } else {
          status = "failed";
        }

        if (callback) callback({value: popup, status: status});
      }

      return popup;
    },

    getFile : function(file, callback) {
      var that = this;
      $.ajax({
        url: file,
        dataType: "html",
        success: function(data) {
            callback.apply(that, [data]);
        },
        cache: false
      });
    },

    render : function(data, template, callback) {
      this.getFile(template, function(file) {
        var hbTemplate = Handlebars.compile(file);
        callback(hbTemplate(data));
      });
    },

    queryParams: function(callback, url) {
      var searchStr = url || window.location.search;
      if(typeof searchStr === "undefined" ||  searchStr === "" || searchStr.indexOf('?') == -1) {
        return {};
      }

      var obj = this.paramStringToObject(searchStr.slice(searchStr.indexOf('?') + 1), callback);
      return obj;
    },

    addParamsToUrlString: function(url, paramsObj, URIEncode) {
      var newParamsObj = {};
      var existingParamsObj;

      if (url.indexOf("?") > -1) {
        existingParamsObj = window.R.utils.queryParams(null, url);
      } else {
        existingParamsObj = {};
      }

      // Add new keys
      for (var key in paramsObj) {
        existingParamsObj[key] = paramsObj[key];
      }


      // Remove all params from URL string
      if (url.indexOf("?") > -1) {
        url = url.substring(0, url.indexOf("?"));
      }

      // Add new combined set of params
      url = url + "?" + window.R.utils.objectToParamString(existingParamsObj, URIEncode);

      return url;
    },

    locationWithNewParams: function(params, paramsToDel = []) {
      var queryObj, url;

      if (typeof(params) === "string") {
        params = this.paramStringToObject(params);
      }

      queryObj = window.R.utils.queryParams();
      paramsToDel.forEach(function (param) {
        delete queryObj[param];
      });

      for(var key in params) {
        queryObj[key] = params[key];
      }
      var queryParams = $.param(queryObj);

      url = window.location.pathname + "?" + queryParams;

      if(window.location.hash !== "") {
        url += window.location.hash;
      }

      // window.location = url;
      return url;

    },

    guid: function() {
      return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0,3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
    },

    paramStringToObject: function(paramString, callback) {
      // PETE - 2015-06-10
      // This algorithm is pretty terrible, as I'm starting to hack it up
      // We should probably look to replace this
      var obj = {},
            hashes = decodeURIComponent(paramString).split('&');

      for(var i = 0; i < hashes.length; i++)
      {
          // split on = but not == because of base64
          // TODO: there has to be a better way, but because of the 2nd '='
          // character negation class, the first letter of value will go away
          // with the split, so i bring it back by wrapping it in a group
          // and join it up later
          var paramArray = hashes[i].split(/=([^=])/);

          if(paramArray[1]) { // if there is a value
            paramArray = [paramArray[0], paramArray[1]+paramArray[2]];
          } else {
            paramArray = [paramArray[0].split(/=/)[0], ""];
          }

          if(callback) {
            paramArray = callback(paramArray[0], paramArray[1]);
          }
          obj[paramArray[0]] = paramArray[1];
      }
      return obj;
    },

    objectToParamString: function(obj, URIEncode) {
        var toEncode = URIEncode === true || URIEncode === undefined ? true : false;

        return Object.keys(obj).map(function(key) {
                var value;

                if (toEncode) {
                    value = encodeURIComponent(key) + '=' +
                        encodeURIComponent(obj[key]);
                } else {
                    value = key + '=' + obj[key];
                }

                return value;


            }).join('&');
    },

    inherits: function(subClass, superClass) {
      var F = function() {};

      if (!superClass) {return;}

      F.prototype = superClass.prototype;
      subClass.prototype = new F();
      subClass.prototype.constructor = subClass;

      subClass.superclass = superClass.prototype;
      if (superClass.prototype.constructor === Object.prototype.constructor) {
        superClass.prototype.constructor = superClass;
      }
    },

    formatDate: function(date) {
      if($body && $body.data('slashdateFormat')) {
        var format = $body.data('slashdateFormat').toUpperCase();
      } else {
        var format = $(document).find('body').data('slashdateFormat').toUpperCase();
      }
      return moment(date, format);
    },

  // Deprecated or can we still use this?
    installFirefox: function(e) {
      var el = e.target;
      var params;
      e.preventDefault();

      params = {
        "Recognize": {
          URL: el.href,
          IconURL: el.getAttribute("data-iconURL"),
          toString: function () { return this.URL; }
        }
      };

      InstallTrigger.install(params);

      return false;
    },

    createDataTable: function(columns, $table, options) {
      var that = this;
      var dataTable;

      var dataTableLanguages = {
        'es': '//cdn.datatables.net/plug-ins/1.10.21/i18n/Spanish.json',
        'fr': '//cdn.datatables.net/plug-ins/1.10.21/i18n/French.json',
        'fr-CA': '//cdn.datatables.net/plug-ins/1.10.21/i18n/French.json',
        'ar': '//cdn.datatables.net/plug-ins/1.10.21/i18n/Arabic.json',
        'zh-TW': {
          "processing": "處理中...",
          "loadingRecords": "載入中...",
          "lengthMenu": "顯示 _MENU_ 項結果",
          "zeroRecords": "沒有符合的結果",
          "info": "顯示第 _START_ 至 _END_ 項結果，共 _TOTAL_ 項",
          "infoEmpty": "顯示第 0 至 0 項結果，共 0 項",
          "infoFiltered": "(從 _MAX_ 項結果中過濾)",
          "infoPostFix": "",
          "search": "搜尋:",
          "paginate": {
            "first": "第一頁",
            "previous": "上一頁",
            "next": "下一頁",
            "last": "最後一頁"
          },
          "aria": {
            "sortAscending": ": 升冪排列",
            "sortDescending": ": 降冪排列"
          }
        }
      };

      var language = dataTableLanguages[$html.attr('lang')];

      options = options || {};
      options.order = options.order || [[1, "asc"], [2, "asc"], [3, "asc"]];

      this.dataTableTimer = this.dataTableTimer || 0;

      if ($table.constructor === String) {
        $table = $($table);
      }

      if ($table.length) {
        clearTimeout(this.dataTableTimer);

        var dtOpts = {
          rowReorder: options.rowReordering,
          ordering: true,
          paging: true,
          searching: options.searching || true,
          responsive: false,
          search: options.search ? {search: options.search} : {},
          pageLength: 25,
          processing: true,
          serverSide: true,
          createdRow: options.createdRow,
          "columns": columns,
          ajax: {
            url: (options.url || $table.data('endpoint') || $table.data('source')),
            data: options.ajaxData
          },
          "order": options.order,
          stateDuration: 0,
          bDestroy: true
        };

        if (language) {
          if (typeof language === 'string') {
            dtOpts.language = {
              url: language
            };
          } else {
            dtOpts.language = language;
          }
        }

        dtOpts.language = dtOpts.language || {};
        dtOpts.language.processing = '<div class=\"spinner-circle\"></div>';

        if (options.hasOwnProperty('searching')) {
          dtOpts.searching = options.searching;
        }

        if (options.hasOwnProperty('paging')) {
          dtOpts.paging = options.paging;
        }

        // By default RowReorder will read the data from the reordered rows and
        // update that same data based on the row's new position in the table.
        // It will then redraw the table to account for any changes in ordering.
        // Since we are server side processing for performing other operation,
        // this option can be used to disable automatic data update and redraw.
        // Reference.: https://datatables.net/reference/option/rowReorder.update

        if (options.rowReordering == true) {
          dtOpts.rowReorder = {
            update: false
          }
        }

        if (options.dataSrc) {
          dtOpts.dataSrc = options.dataSrc;
        }

        if (options.dom) {
          dtOpts.dom = options.dom;
        }

        if(options.order) {
          dtOpts.order = options.order;
        }

        if(options.drawCallback) {
          dtOpts.drawCallback = options.drawCallback;
        }

        if(options.lengthMenu) {
          dtOpts.lengthMenu = options.lengthMenu;
        } else {
          var vals = [25, 50, 100, 250];
          var labels = vals.slice();
          if (options.includeAllOptionInLengthMenu) {
            vals.push(-1);
            labels.push('All');
          }
          dtOpts.lengthMenu = [ vals, labels ];
        }

        if(options.rowGroup) {
          dtOpts.rowGroup = options.rowGroup;
        }

        // Expected `colvis` attribute's signature:
        // {
        //    enabled: <boolean>,
        //    insertButtonBeforeSelector: <css selector>,
        //    columnsToShowByDefault: <array of column names to be shown before colvis kicks in>
        // }
        if(options.colvis && options.colvis.enabled) {
          dtOpts = $.extend(true, dtOpts, that.getColVisStateSaveDatatablesOptions(columns));

          if(options.colvis.columnsToShowByDefault) {
            // Only show certain columns by default. User however can toggle the visibility using the colvis toggler.
            // User's preference of the column visibilities is persisted in browsers local storage. The colvis state
            // stored in local storage overrides these explicit default column visibility settings.
            dtOpts.columns = columns.map(function(column){
              var columnShouldBeHidden = options.colvis.columnsToShowByDefault.indexOf(column.data) === -1;
              if (columnShouldBeHidden) {
                column.visible = false;
              }
              return column;
            });
          }

          // Property `autoWidth` is by default `true` for a DataTable. However, for datatables with colvis support, to
          // get columns (and table) width to work gracefully on `page:restore`, `autoWidth` needs to be turned off.
          dtOpts.autoWidth = options.autoWidth || false;

          /*
           * This colvis setup needs to be wrapped in initComplete callback to make it work for languages setup with remote URL
           * Otherwise, it fires too early - before the insertButtonBeforeSelector container is loaded -
           *   which is loaded after the request for the language file is complete
           **/
          dtOpts.initComplete = function () {
            that.bindColVisButton(dataTable, $(options.colvis.insertButtonBeforeSelector));
            that.updateColvisCount(this);

            var $colvisButton = $('.dt-button.buttons-collection.buttons-colvis');
            if($colvisButton.length > 1){
              /* Sometimes, two colvis buttons are generated due to their wrapping in the initComplete callback,
               * which is used to make colvis work for languages set up with a remote URL.
               * Remove the first colvis button and update the count to fix colvis issue during turbolink visit.
               **/
              $colvisButton.first().remove();
              that.updateColvisCount(this);
            }
          };
        }

        // add processing class to datatable to show overlay
        // un-delegation apparently not needed
        var processingClass = 'dt-processing';
        $table.on( 'processing.dt', function ( _e, _settings, processing ) {
          var action = processing ? 'addClass' : 'removeClass';
          $table[action](processingClass);
        });
        $table.on( 'preInit.dt', function () { // needed for initial phase
          $table.addClass(processingClass);
        });

        dataTable = $table.DataTable(dtOpts);

        // Manual firing AJAX request rowReordering.
        dataTable.on( 'row-reorder', function ( e, diff, edit) {
          var sort_endpoint = $($(this)[0]).attr("sort-endpoint");
          var changedRowsData = [];

          changedRowsData = diff.map(function (each_diff) {
            each_diff['rowID'] = each_diff.node.id;
            return each_diff;
          });
          $.ajax({
            url: sort_endpoint,
            dataType: "JSON",
            type: "PATCH",
            data: {
              changed_rows_data: JSON.stringify(changedRowsData)
            }
          });
        });

        $(".litatable-radio-filter input[type='radio']").change(function(e) {
          e.preventDefault();
          var dataColumn = $(this).val();
          dataTable.rowGroup().dataSrc(dataColumn);
          dataTable.draw();
        });

        // 2023 update: Added a condition to solve undefined issues on turbolink revisit
        var tableData = $table.dataTable()
        if (typeof(tableData.fnSetFilteringDelay) !== "undefined") {
          //filtering delay for bug due to response data size
          tableData.fnSetFilteringDelay();
        }

        if (options.useButtons) {
          var exportButtons;
          if(options.exportOptions){
            var buttonOpts = {
              buttons: [
                $.extend( true, {}, options.exportOptions, {
                  extend: 'copy'
                } ),
                $.extend( true, {}, options.exportOptions, {
                  extend: 'csv'
                } ),
                $.extend( true, {}, options.exportOptions, {
                  extend: 'excelHtml5'
                } ),
                $.extend( true, {}, options.exportOptions, {
                  extend: 'print'
                } )]
            }
            exportButtons = new $.fn.dataTable.Buttons( dataTable, buttonOpts);
          } else {
            exportButtons = new $.fn.dataTable.Buttons( dataTable, {
              buttons: ['copy', 'csv', 'excelHtml5', 'print']
            });
          }
          exportButtons.container().insertAfter( $(options.insertButtonsAfterSelector ) );
        } else if(options.serverSideExport) {

          // options = $.extend(true, options, options.serverSideExport);
          exportButtons = new $.fn.dataTable.Buttons( dataTable, {buttons: options.serverSideExport.buttons});
          exportButtons.container().insertAfter( $(options.serverSideExport.insertButtonsAfterSelector ) );
        }

      } else {
        this.dataTableTimer = setTimeout(function() {
          that.createDataTable(columns, $table);
        }, 500);
      }

      return dataTable;
    },
    bindColVisButton: function(dataTable, insertBeforeSelector) {
      var colVisButton = new $.fn.dataTable.Buttons( dataTable, {
        buttons: [
          {
            titleAttr: 'Toggle column visibility',
            text: "<div id='colvis-icon-wrapper'></div><span id='colvis-hidden-col-count' class='smallPrint'></span>",
            extend: 'colvis',
            // Exclude those that are hardcoded to be invisible in  respective *_datatable.rb. An example is completed_tasks_datatable's date column, which is massaged while rowGroup-ing.
            columns: ":not([data-visible='false'])"
          }
        ]
      });
      colVisButton.container().insertBefore(insertBeforeSelector);
      // Since the initComplete(:441) callback fires late, tooltip needs to
      // be manually initialized for the colvis button.
      this.initTooltipWithWait(colVisButton.container().children());
    },
    getColVisStateSaveDatatablesOptions: function(columns) {
      var opts =  {};
      opts.stateSave = true;
      var that = this;

      // Only save states of `columns` and `time`; `time` is apparently needed to write to localstorage.
      var propertiesToStateSave = ["time", "columns"];

      // See https://datatables.net/reference/option/stateSaveCallback,
      // The `data` given to the function `stateSaveParams` represents state of the current datatable. For instance:
      // {
      //   "time":   {number}               // Time stamp of when the object was created
      //   "start":  {number}               // Display start point
      //   "length": {number}               // Page length
      //   "order":  {array}                // 2D array of column ordering information (see `order` option)
      //   "search": {
      //   "search":          {string}  // Search term
      //   "regex":           {boolean} // Indicate if the search term should be treated as regex or not
      //   "smart":           {boolean} // Flag to enable DataTables smart search
      //   "caseInsensitive": {boolean} // Case insensitive flag
      // },
      //   "columns" [
      //     {
      //       "visible": {boolean}     // Column visibility
      //       "search":  {}            // Object containing column search information. Same structure as `search` above
      //     }
      //     ]
      // }
      // We discard state properties that we don't want saved.
      opts.stateSaveParams =  function(settings, data) {
        // BEGIN - only save states of column visibility; discard state properties that need not be saved.
        Object.keys(data)
          .forEach(function(property) {
            if (propertiesToStateSave.indexOf(property) === -1) {
              delete data[property];
            }
          });
        data.columns.forEach(function(column){
          Object.keys(column).forEach(function(property){
            if (property !== "visible") {
              // discard all properties but `visible`
              delete column[property];
            }
          });
        });
        // END - only save states of column visibility; discard state properties that need not be saved.

        that.updateColvisCount(this);
      };
      return opts;
    },
    // updates count of hidden columns beside colVis button
    updateColvisCount: function(datatable) {
      // Exclude those that are hardcoded to be invisible in  respective *_datatable.rb.
      var visibilityArray = datatable.api().table().columns(":not([data-visible='false'])").visible();
      var hiddenColCount = 0;
      for(var i = 0; i < visibilityArray.length; ++i){
        if(visibilityArray[i] === false){
          hiddenColCount++;
        }
      }
      $("#colvis-hidden-col-count").html( hiddenColCount === 0 ? "" : hiddenColCount);
    },
    redrawDatatable: function($table) {
      var dt = $table.DataTable();
      dt.draw(); // will reload ajax
      window.recognize.patterns.formLoading.resetButton();
    },
    interval_conversion_factor: function(from, to) {
      //requires gon.intervals and gon.interval_conversion_map to be loaded in controller
      if(from === to) {
        return 1;
      } else if(from > to ) {
        return gon.interval_conversion_map[from][to];
      } else {
        return -1 * gon.interval_conversion_map[to][from];
      }
    },
    getCurrencyPrefix: function() {
      //requires gon.currency to be loaded in controller
      return gon.currency;
    },
    //
    // Formats a number to currency format.
    // opts:
    //  includeSymbol - true by default.
    //
    formatCurrency: function(num, opts) {
      opts = opts || {};
      var includeSymbol = opts.includeSymbol === undefined ? true : opts.includeSymbol;

      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
      // Use of `minimumFactionDigits: 2` ensures there is always atleast 2 digits after decimal in locale'd string.
      // This is those `num` that have less than 2 digits after decimal.
      // For eg: parseFloat((12.1).toFixed(2)) = 12.1, but we need 12.10
      var val = (parseFloat(num.toFixed(2))).toLocaleString(undefined, {minimumFractionDigits: 2});

      //if only zeroes after decimal, get rid of them
      val = val.replace(/\.00?$/, '');

      if (includeSymbol) {
        val = this.getCurrencyPrefix() + val;
      }
      return val;
    },
    listenForWindowMessage: function(){
      // For logging in from iframe / Sharepoint
      window.addEventListener("message", function(event) {
        if (event.origin === window.R.host && event.data === "Recognize wants to login in please") {
          event.source.postMessage("Logging in", event.origin);
        }
      }, false);

    },
    l: function(key) {
      // localization stub
      var lookup = {'dict.confirm': 'Confirm', 'dict.cancel': 'Cancel', 'dict.submit': 'Submit'};
      return lookup[key] || key;
    },
    remoteSwal: function(userOpts) {
      var defaultOpts = {
        html: "<img src='/assets/icons/ajax-loader.gif'>",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: window.R.utils.l('dict.submit'),
        cancelButtonText: window.R.utils.l('dict.cancel')
      };
      var opts = $.extend(userOpts, defaultOpts);
      return Swal.fire(opts);
    },
    // prevents infiniteScroll page scroll binding from carrying on to other pages with Turbolinks
    destroyInfiniteScroll: function ($elem) {
      if ($elem && $elem.data('infiniteScroll')) {
        $elem.infiniteScroll('destroy');
        // fully clear the data as well: stackoverflow.com/a/11151931
        $elem.data('infiniteScroll', null);
      }
    },
    getSelectedLabelsFromSelect: function($select) {
      var selectedOptions = $select.find("option:selected");
      return $.map(selectedOptions, function(item, i){return item.text;});
    },
    isLoggedInLayout: function() {
      return !!$("body").data('loggedin');
    },
    resetCaptcha: function() {
      if(typeof(grecaptcha) != 'undefined') {
        try{
          grecaptcha.reset();
        } catch(e) {
          console.error(e);
        }
      }
    },

    // Initializes a select2 component after succesful remote data fetch via ajax.
    // Can be used in scenarios where you need to prepopulate the select2 component before initializing
    // or in scenarios where standard select2 remote data fetching is not ideal.
    // For more on its genesis: https://github.com/Recognize/recognize/pull/3197#discussion_r464739011
    select2RemoteSingleton: function(endpoint, selectWrapper, ajaxOpts){
      ajaxOpts = ajaxOpts || {};
      return new Promise(function(resolve, reject) {
        var $selectWrapper= $(selectWrapper);
        var $ajaxSpinner = $selectWrapper.find(ajaxOpts["ajaxSpinnerSelector"] || ".ajax-loader");
        var $warningMessageContainer = $selectWrapper.find(ajaxOpts["warningMessageContainerSelector"] || ".text-warning");
        var defaultAjaxOpts = {
          url: endpoint,
          type: 'GET',
          dataType: 'json',
          beforeSend: function(){
            $warningMessageContainer.empty();
            $ajaxSpinner.show();
          },
          success: function(data) {
            new Select2(function() {
              resolve(data);
            });
          },
          error: function(xhr){
            reject(xhr);
          },
          complete: function(){
            $ajaxSpinner.hide();
          }
        };
        ajaxOpts = $.extend({}, defaultAjaxOpts, ajaxOpts);
        $.ajax(ajaxOpts);
      });
    },

    select2RemoteUsers: function($selectEl, opts) {
      opts = opts || {};
      var avatar_col_class = opts.avatar_col || 'col-4';
      var name_col_class = opts.name_col || 'col-8';
      var label_col_class = 'col-sm-6';
      var include_email = opts.include_email || false;
      var include_disabled = opts.include_disabled || false;

      // formatUser function
      function formatUser(user) {
        if (user.loading) {
          return "Please wait...";
        }

        if (user.avatar_thumb_url === R.defaultAvatarPath) {
          user.avatar_thumb_url = "/assets/" + R.defaultAvatarPath;
        }

        var template = HandlebarsTemplates['user/userAvatar'];
        var name = user.label;
        if (include_email) {
          label_col_class = '';
          name = user.label + " - " + user.email + " (" + user.id + ")";
        }
        return template({
          avatar_url: user.avatar_thumb_url,
          name: name,
          avatar_col_class: avatar_col_class,
          name_col_class: name_col_class,
          label_col_class: label_col_class
        });
      }

      // formatUserSelection function
      function formatUserSelection(user) {
        if (include_email && user.email) {
          return user.label + " - " + user.email + " (" + user.id + ")";
        } else {
          return user.label || user.text;
        }
      }

      var url = "/coworkers?include_disabled="+include_disabled;
      var dept = window.R.utils.queryParams().dept;
      var params_obj = {}

      if(opts.network) {
        params_obj.network = opts.network;
      }
      if(dept) {
        params_obj.dept = dept;
      }
      url = window.R.utils.addParamsToUrlString(url, params_obj);

      new Select2(function(){
        $selectEl.select2({
          placeholder: (opts.placeholder || "Please select a user"),
          allowClear: true,
          ajax: {
            url: url,
            dataType: 'json',
            delay: 250,
            data: function(params) {
              return {
                term: params.term, // search term
                page: params.page,
                include_self: true,
                by_email: include_email
              };
            },
            processResults: function(data, page) {
              return {
                results: data
              };
            },
            cache: true
          },
          escapeMarkup: function(markup) {
            return markup;
          },
          minimumInputLength: 1,
          templateResult: formatUser,
          templateSelection: formatUserSelection
        });

      });
    },

    setupSelectFilters: function () {
      [".company-roles-select", ".team-select", ".badge-select"].forEach(selector => {
        new Select2(function () {
          var $select = $(selector).select2(R.utils.companyRoleSelect2TemplateOpts({ theme: 'bootstrap-5' }));
          $select.on("select2:select", selectFilterEvent);
        });
      });

      function selectFilterEvent() {
        var $this = $(this), queryObj, url;
        queryObj = window.R.utils.queryParams();
        if ($this.val() === "all") {
          delete queryObj[$this.prop('name')];
        } else {
          queryObj[$this.prop('name')] = $this.val();
        }
        var queryParams = $.param(queryObj);
        url = window.location.pathname + "?" + queryParams;
        if (window.location.hash !== "") {
          url += window.location.hash;
        }
        window.location = url;
      }
    },

    // This is for requests throttled by Rack::Attack
    setThrottledErrorIfNeeded: function(xhr) {
      if (xhr.status !== 429) return;

      var message = 'Too many requests, please try again';

      var retryAfter = xhr.getResponseHeader('retry-after');
      if (retryAfter)
        message += ' ' + retryAfter + ' seconds later.';

      xhr.responseJSON = {
        type: 'error',
        errors: {base: [message]}
      };
    },

    safe_feather_replace: function() {
      if (typeof feather !== 'undefined')
        feather.replace();
    },

    setupSessionInactivityCheckTimer: function(){
      if(window.R.utils.isLoggedInLayout()){
        var duration = gon.user_session.session_check_interval * 1000 // convert to ms
        if(!duration) return; // double check to avoid using the very low default value

        window.R.checkExpiredSessionIntervalId = setInterval(window.R.utils.checkSessionExpired, duration);
        // To keep track of sessions across multiple tabs
        var currentTime = new Date();
        var pingSessionCheckAt = currentTime.getTime() + duration;
        localStorage.setItem("pingSessionCheckAt", pingSessionCheckAt);
      }
    },

    checkSessionExpired: function() {
      var email = $("body").data('email');
      var dataOpts ={
        session_check: true
      };
      var anchorTag = window.location.hash;
      var queryParams = R.utils.queryParams();

      if(anchorTag){
        dataOpts.anchor_tag = anchorTag;
      }

      if(queryParams.viewer){
        dataOpts.viewer = queryParams.viewer;
      }
      var sessionCheckEndpoint = "/" + gon.user_session.session_check_endpoint;

      $.get(sessionCheckEndpoint, dataOpts, function(data, status){
        if(!data.status){
          localStorage.removeItem("pingSessionCheckAt");
          var idp = new window.R.IdpRedirecter();
          var current_network = gon.user_session.current_network;
          idp.checkIdp(email, null, {session_expired: "true", network: current_network});
        }
        else{
          var currentTime = +new Date();
          var pingSessionCheckAt = localStorage.getItem("pingSessionCheckAt");

          if(currentTime < pingSessionCheckAt){
            var remainingTime = pingSessionCheckAt - currentTime;
            // setting the minimum to 2 secs to prevent possibility of
            // multiple session check requests.
            if(remainingTime < 2000){
              remainingTime = 2000;
            }
            window.R.checkExpiredSessionIntervalId = setTimeout(window.R.utils.checkSessionExpired, remainingTime);
          }
        }
      });
    },

    simpleAjaxPostWithoutCallbacks: function(url, data){
      $.ajax({
        url: url,
        data: data,
        method: "post"
      });
    },

    layoutIsotopeIfRelevantAndWatchForImages: function ($el){
      var $isotopeEl = $el.closest('.isotope');
      if (!$isotopeEl.length) return;

      var triggerLayout = function () {
        $isotopeEl.isotope("reLayout");
      }

      triggerLayout();
      if ($el.find('img').length){
        $el.imagesLoaded(triggerLayout);
      }
    },


    setPlacementForTooltip: function(tip, element){
      if($(element).closest(".trumbowyg-button-pane").length){
        return 'top';
      }
      else{
        return 'bottom';
      }
    },

    renderFeedbackMessageAfterSave: function(message, $setting, fadeOutTime, textColor){
      var $message = $("<div class='message'>").html(message).addClass("text-"+textColor);
      var $label = $setting.parent().children("label"); // Allow $setting to be a label itself
      var $existingMessage = $label.children('.message');
      if ($existingMessage.length) {
        /*
         * Presumes this .message element(s) was added by this same util method
         * The presence of these existing .message elements are non-problematic in most cases,
         *   but the duplication is visible when performing multiple consecutive updates quickly
         */
        $existingMessage.replaceWith($message);
      } else {
        $label.append($message);
      }
      $message.fadeOut(fadeOutTime);
    },

    /*
    * This can be used instead of history.pushState() for Turbo backward navigation compatibility
    * Pushes history and updates Turbo last location
    */
    pushHistoryWithTurbo: function(newURLstr) {
      const newURL = new URL(newURLstr);
      Turbo.navigator.history.push(newURL);
      Turbo.navigator.view.lastRenderedLocation = newURL;
    },

    showTurboProgressBar: function() {
      Turbo.navigator.delegate.adapter.showProgressBar();
    },
    setTurboProgressBar: function(value) {
      Turbo.navigator.delegate.adapter.progressBar.setValue(value);
    },
    hideTurboProgressBar: function() {
      R.utils.setTurboProgressBar(1);
      Turbo.navigator.delegate.adapter.progressBar.hide();
    },

    // ES5 debounce from https://gist.github.com/Sidd27/daa8c600694ee99b62daabcba0af85cb
    debounce: function(fn, delay, immediate) {
      var timeout;
      return function() {
        var context = this, args = arguments;
        var later = function() {
          timeout = null;
          if (!immediate) fn.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, delay);
        if (callNow) fn.apply(context, args);
      };
    },

    /*
     * This interval checking is needed for datatables because bootstrap script is loaded separately from application-3p (at the bottom of _head)
     *   (which is done to load a separate version for IE)
     *   which can cause timing issue inside datatables, as application.js is sequentially executed way ahead of bootstrap script (for some reason)
     *   (resulting in datatable to be stuck at loading state)
     *   This issue has been reported sometimes during QA too and is seen more frequently in CI.
    **/
    initTooltipWithWait: function ($targetEl) {
      $targetEl = $targetEl || $body;

      if($.fn.tooltip){
        $targetEl.tooltip();
      } else {
        var iterationCount = 0;
        var intervalId = setInterval(function () {
          if($.fn.tooltip){
            $targetEl.tooltip();
            clearInterval(intervalId);
          } else {
            if (iterationCount < 20) {
              iterationCount++;
            } else {
              clearInterval(intervalId); // stop trying
            }
          }
        }, 500);
      }
    },

    copyText: function(text, button) {
      const $copyButton = $(button);
      const $textToCopy = $(text);
      const that = this;

      $copyButton.on(R.touchEvent, function() {
        navigator.clipboard.writeText($textToCopy.text())
            .then(function() {
              that.writeToast('Copied');
            })
            .catch( function(error) {
              console.error('Failed to copy text: ', error);
            });
      });
    },

    writeToast: function(text, type) {
      type = type || 'success';

      const html = '  <div class="position-fixed flash-wrapper" style="z-index: 11000; top: 85px; left: 10px;">\n' +
          '      <div class="toast align-items-center show text-white bg-'+type+' border-0" role="alert" aria-live="assertive" aria-atomic="true">\n' +
          '        <div class="d-flex">\n' +
          '          <div class="toast-body">'+text+'</div>\n' +
          '          <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>\n' +
          '        </div></div></div></div>';

      $body.append(html);

      setTimeout(function() {
        $('.flash-wrapper').fadeOut(2000, function() {
          $('.flash-wrapper').remove();
        });
      }, 4000);
    },

    remoteSelect2: function(opts) {
      opts = opts || {};
      var selector = opts.selector || '.remote-select2';
      var $el = $(selector);
      var select2Opts = $el.data('remoteSelect2') || {};
      var remoteSelect2CallbackKey = 'remoteSelect2CallbackAdded'

      new Select2(function () {
        $el.select2(select2Opts);
      });

      function handleRemoteSelect2Event(event, action) {
        var $evtSelect2el = $(event.target);
        var evtSelect2Opts = $evtSelect2el.data('remoteSelect2') || {};
        var url = evtSelect2Opts.url;
        var remoteAttributeName = evtSelect2Opts.remote_attribute_name;

        var data = { category_action: action };
        data[remoteAttributeName] = event.params.data.id;
        window.R.utils.simpleAjaxPostWithoutCallbacks(url, data);
      }

      // This condition is added to prevent registering a callback again on turbolink revisit
      if (!$body.data(remoteSelect2CallbackKey)) {
        $body.on('select2:select', selector, function(event) {
          handleRemoteSelect2Event(event, 'add')
        });

        $body.on('select2:unselect', selector, function(event) {
          handleRemoteSelect2Event(event, 'remove')
        });
      }
      $body.data(remoteSelect2CallbackKey, 'true');

    },

    // modularized method to be applied to any CompanyRole select2
    // this allows showing the sync source as superscript for dynamic role options (it should be passed from server-side as well)
    // ex. .select2( R.utils.companyRoleSelect2TemplateOpts({ <optional other opts> }) )
    companyRoleSelect2TemplateOpts: function(otherOpts) {
      var template = function(companyRole) {
        var result = $("<span />").attr('class', 'company_role-' + companyRole.id).text(companyRole.name || companyRole.text);
        if (companyRole.element && companyRole.element.attributes.sync_source) {
          result.append($("<span />").attr('title', gon.dynamic_roles_info).append("&nbsp;<sup><i>"+companyRole.element.attributes.sync_source.value+"</i></sup>"));
        }
        return result;
      };

      var templateOpts = {
        templateResult: template,
        templateSelection: template
      };

      if (!otherOpts){
        return templateOpts;
      }
      return $.extend(otherOpts, templateOpts);
    },

    progressJob: function(jobId, successCallback, failureCallback) {
      var interval = setInterval(function() {
        $.ajax({
          url: '/progress-job/' + jobId,
          success: function(data, success, xhr) {
            if (xhr.status === 205) {
              clearInterval(interval);
              successCallback();
              return;
            }
            var progress;
            var progressStage = data.progress_stage;
            var currentProgress = data.progress_current;
            var maxProgress = data.progress_max;
            progress = data.progress_current / data.progress_max * 100;
            progress = progress.toFixed(0)
    
            if(data.failed_at) {
              $("span.status").html('Failed!');
              clearInterval(interval);
              return;
            }
    
            if(data.locked_at && currentProgress > 0) {
              $(".message").text('');
              $("span.status").html('Running');
              $('#progress-text').text(progressStage + ': ' + currentProgress + ' / ' + maxProgress);
              $(".progress-bar").attr("aria-valuenow", progress);
              $(".progress-bar").css("width", progress + "%");
              $(".progress-bar").text(progress + "%");
              return;
            }
          },
          error: function(jqXHR) {
            clearInterval(interval);
            if (failureCallback) {
              failureCallback();
            } else {
              alert('unexpected error');
            }
          }
        });
      }, 1000);
    
      var cleanup = function() {
        if (interval) {
          clearInterval(interval);
          interval = null;
        }
        if (xhr) {
          xhr.abort();
          xhr = null;
        }
      };
    
      // cleanup on visiting other pages using back/forward navigation buttons.
      document.addEventListener('turbo:before-cache', cleanup, { once: true });
      // avoid unnecessary API calls after redirect is initiated (remove calls on click).
      document.addEventListener('turbo:click', cleanup, { once: true });
    },
    

    // for article, video, etc search
    // currently submitting form on actual submission only (eg. as opposed to keypress, etc) for simplicitly as well as avoid dup request issues
    bindCMSPostSearchEvents: function (searchFormSelector) {
      // Adapted from javascripts/pages/articles/index.js
      var performSearch = function (evt){
        var $target = $(evt.target), // can be input or button
            $input = $target.closest('form').find('#search-input'),
            url = window.location.pathname;
        if ($input.val().trim()) {
          var queryString = $(searchFormSelector).serialize();
          url += "?" + queryString;
        }
        Turbo.visit(url);
      }

      $body.on('submit', searchFormSelector, function(evt) {
        evt.preventDefault(); // prevent browser submission
        performSearch(evt);   // submit manually via turbolinks
      });
    },

    loadLazyImages: function(wrapperSel = '') {
      $(`${wrapperSel} .lazy`).Lazy({
        // your configuration goes here
        scrollDirection: 'vertical',
        effect: 'fadeIn',
        visibleOnly: true,
        onError: function (element) {
          console.log('error loading ' + element.data('src'));
        }
      });
    }
  };

  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
})();
