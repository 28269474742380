const templatesImportMap = import.meta.glob('~/templates/**/*.hbs', { eager: true });
const HandlebarsTemplates = {};

for (const [path, module] of Object.entries(templatesImportMap)) {
  let templateName = path.replace('/templates/', '').replace('.hbs', '')
  const { default: compiledTemplate } = module;
  HandlebarsTemplates[templateName] = compiledTemplate;
}

export default HandlebarsTemplates;
