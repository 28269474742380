<div class="clearfix row">
    <div class="{{#if avatar_col_class}}{{avatar_col_class}}{{else}}col-4{{/if}}">
        <img src="{{avatar_url}}" style="max-width: 100%"/>
    </div>
    <div class="{{#if name_col_class}}{{name_col_class}}{{else}}col-8{{/if}}">
        <div class="clearfix">
            <div class="{{ label_col_class }}">{{name}}</div>
        </div>
    </div>
</div>
