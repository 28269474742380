export default (function() {
  var Select2 = function(callback) {
    if(typeof($('select').select2) === 'function') {
      if (callback) {
        callback();
      }
      addPatchForLockedItems();
    } else {
      $('head').append( $('<link rel="stylesheet" type="text/css" integrity="sha256-Zlen06xFBs47DKkjTfT2O2v/jpTpLyH513khsWb8aSU=" crossorigin="anonymous" />').attr('href', 'https://cdnjs.cloudflare.com/ajax/libs/select2/4.0.0/css/select2.min.css') );
      $('head').append( $('<link rel="stylesheet" type="text/css" integrity="sha256-XLNUEfzPGHBeStES2DbLUURZ3e793BablwzJlYj6W2Q=" crossorigin="anonymous" />').attr('href', 'https://cdnjs.cloudflare.com/ajax/libs/select2-bootstrap-5-theme/1.3.0/select2-bootstrap-5-theme.min.css') );
      $.cachedScript('https://cdnjs.cloudflare.com/ajax/libs/select2/4.0.0/js/select2.min.js', {
        scriptAttrs: {
          integrity: "sha256-WO6QcQSEM5vwHL4eANUd/mzxRqRyxP3RWj+r6FS5qXk=",
          crossorigin: "anonymous"
        }
      }).done(function () {
        if (callback) {
          callback();
        }
        addPatchForLockedItems();
      });
    }

    $(document).on('mouseenter', '.select2-selection__rendered, .select2-selection__choice', function () {
      $(this).removeAttr('title');
    });

  };

  /*************************************************
   * START HACK SELECT2 v4.x does not support locking items
   * remove this code block when this feature is supported
   * See: https://github.com/select2/select2/issues/3339
   */
  $(document).on("select2:unselecting", 'select:not([multiple])', function (e)
  {
    if ($(e.params.args.data.element).attr('locked')) {
      e.preventDefault();
      return false;
    }
    $(this).val('').trigger("change");
  });
  $(document).on("select2:selecting", 'select:not([multiple])', function (e)
  {
    if ($(e.target).find(":selected").attr('locked'))
      return false;
  });
  $(document).on('select2:unselecting', 'select[multiple]', function(e)
  {
    $(e.target).data('unselecting', true);
    if ($(e.params.args.data.element).attr('locked'))
      return false;

  }).on('select2:open', function(e)
  {
    var $target = $(e.target);
    if ($target.data('unselecting'))
    {
      $target.removeData('unselecting');
      $target.select2('close');
    }
  });
  /**************************************************
   * END HACK UNTIL SELECT2 v4.x supports locking items
   ***************************************************/

  /*
   * Fix bug when pressing backspace on locked items (where it splits up and reappears in a buggy way)
   *
   * This callback is targeted at the following library callback (overriding/preventing that for locked item scenario)
   * https://github.com/select2/select2/blob/0a186ebb4875b080f70e5f48d957f025e97194f5/dist/js/select2.js#L2137-L2158
   * it is possibly a bug - the fact that that callback disregards the user cancellation of unselect event
   *
   * Note: Unlike other callbacks above, this needs to be defined on the specific elements - thus after initializing select2
   *       so that it has higher precedence over the library's own callback
   **/
  function addPatchForLockedItems(){
    $('.select2-search--inline').on('keydown', function(e)
    {
      // e.stopPropagation(); //test

      // 1. skip if previous item is not locked
      var prevLockedItem = $(this).prev().find('.select2-selection__choice__locked');
      if (!prevLockedItem.length)
        return;

      /*
       * Note: the guard clauses after this point are not currently required
       *       (as the corresponding library callback we are targeting handles the same case only)
       *       but adding to be defensive (eg. during updates)
       **/

      var searchVal = $(this).find('.select2-search__field').val();
      // 2. skip if search term is/was present, as this will just result in the search character being removed
      if (searchVal)
        return;

      // 3. skip if not backspace keypress
      var backspaceKeyIndex = 8; // num ref taken from the select2 js
      if (e.which !== backspaceKeyIndex)
        return;

      e.stopPropagation();
    });
  }

  return Select2;

})();
