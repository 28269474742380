{{#items}}
  <li>
    <a>
      {{#avatar_thumb_url}}
        {{#if ../avatar_is_default}}
          <img src="{{.}}" width="25" height="25" class="avatar default-avatar" />
        {{else}}
          <img src="{{.}}" width="25" height="25" class="avatar" />
        {{/if}}
      {{/avatar_thumb_url}}
      
      {{label}}

      {{#jobTitle}}
      | {{.}}
      {{/jobTitle}}
      {{#team}}
        | {{{.}}}
      {{/team}}
    </a>
  </li>
{{/items}}
