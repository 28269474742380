export default class FormErrors {
  constructor($form, errors, data) {
    this.$form = $form;
    this.errors = errors;
    this.data = data;
  }

  setErrorText(errorText, element, inputId) {
    let $el;
    const $generatedErrorId = "error-" + (Math.random() + '').replace('0.', '');
    const $errorElement = $('<div class="not-visible error" role="alert" id=' + $generatedErrorId + '><div class="alert alert-danger d-inline-block"></div></div>');

    $errorElement.find('.alert').html(errorText);

    if (element === "base") {
      const $base = $("#base_errors");
      if ($base.length === 0) {
        this.$form.prepend($errorElement);
      } else {
        $("#base_errors").before($errorElement);
      }
    } else {
      this.ensureAriaDescribedbySet(inputId, $generatedErrorId);

      $el = $(element);

      if ($el.length === 0) {
        // First look inside this.$form
        $el = this.$form.find("#" + element);
        // Then, look outside this.$form (in the whole DOM), if needed.
        if ($el.length === 0) {
          $el = $("#" + element);
        }
      }

      if (this.data && this.data.formuuid) {
        $("form[data-formuuid=" + this.data.formuuid + "]").find($el).before($errorElement);
      } else {
        if ($el.length === 0) {
          element += ("_" + this.data.id);
          $el = $("#" + element);
        }
        $el.before($errorElement);
      }
    }
    setTimeout(function () { $errorElement.removeClass("not-visible"); }, 50);
  }

  /*
  Returns an array of consolidated error messages from errors object.

  This is handy when manual handling of errors is necessary when input ids in current view don't match what comes
  back in server response.
  Example:
  this.errors = { "attribute_x": ["Attribute x error"], "attribute_y": ["Attribute y error 1", "Attribute y error 2"] }
  getErrorMessages() // Expected output: ["Attribute x error", "Attribute y error 1", "Attribute y error 2"]
  */
  getErrorMessagesFromErrorsObject() {
    let errorMessages = [];
    let normalizedErrors = this.resolveErrorsThatStartWithCaret(this.errors);
    for (let key in normalizedErrors) {
      if (normalizedErrors.hasOwnProperty(key)) {
        let messages = normalizedErrors[key];
        messages.forEach(function (message) {
          errorMessages.push(message);
        });
      }
    }
    return errorMessages;
  }

  /*
  There are certain error messages that begin with a caret (^) symbol. (See en.yml for some examples). These error
  message signify that they are used standalone without the relevant attribute prepended to the message.
    For eg:
      self.errors.add(:file, "must be present") outputs the error message as "File must be present"
                VS
     self.errors.add(:file, "^The extension is invalid") outputs the error message as "The extension is invalid"

  This logic is handled in JsonResouce#json_for_errors. However, this logic only gets executed for responses triggered
  by `respond_with` method. Reponses that instead directly render json (Eg: `render json: { errors: @obj.errors })`
  aren't intercepted by JsonResource ; this causes the error messages to have caret(^) symbol prepended at the
  beginning of the error message.

  This method attempts to solves this.
  */
  resolveErrorsThatStartWithCaret(errors) {
    for (let key in errors) {
      if (errors.hasOwnProperty(key)) {
        let messages = errors[key];
        messages = messages.map(function (message) {
          let messageStartsWithCaret = message.indexOf("^") === 0;
          if (messageStartsWithCaret) {
            return message.replace(/^\^/, '');
          }
          return message;
        });
        errors[key] = messages;
      }
    }
    return errors;
  }

  renderErrors() {
    this.clearErrors();
    this.errors = this.resolveErrorsThatStartWithCaret(this.errors);

    for (let element in this.errors) {
      let el = document.getElementById(element);
      let targetEl = (el && el.dataset && el.dataset.errorelement) ? el.dataset.errorelement : element;
      let elementId = (el && el.id) ? el.id : null

      if (this.errors.hasOwnProperty(element)) {
        if (this.errors[element].constructor === Array) {
          this.errors[element].forEach(function (error) {
            this.setErrorText(error, targetEl, elementId);
          }.bind(this));
        } else {
          this.setErrorText(this.errors[element], targetEl);
        }
      }
    }
  }

  clearErrors() {
    this.$form.find(".error").remove();
    $('.form-errors').remove();
    this.$form.find("[aria-describedby]").removeAttr('aria-describedby');
  }

  // This code was copied over and adapted from the parent setErrorText method
  ensureAriaDescribedbySet(inputId, generatedErrorId) {
    if (!inputId) {
      return;
    }

    let $inputField = this.$form.find("#" + inputId)
    if (!$inputField.length) {
      $inputField = $("#" + inputId);
    }

    if ($inputField.length) {
      $inputField.attr("aria-describedby", generatedErrorId);
    }
  }
}
