<span>{{task_submission.date}}</span>
{{#if task_submission.summary}}
    <span> | {{task_submission.summary}}</span>
{{/if}}

{{#if task_submission.is_user_approvable}}
    <span class="resolve-task-submission-wrapper">
  <a href="javascript://" data-tasksubmissionid="{{task_submission.id}}"
     class="resolve-task-submission-link btn btn-primary"
     data-editendpoint={{task_submission.edit_endpoint}}>Resolve</a>
</span>
{{/if}}
